<!-- AppIconSortUp.vue -->
<template>
  <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_10520488">
    <path
      class="icon-color"
      clip-rule="evenodd"
      d="m17.5303 15.0303c-.2929.2929-.7677.2929-1.0606 0l-4.4697-4.4696-4.46967 4.4696c-.29289.2929-.76777.2929-1.06066 0s-.29289-.7677 0-1.0606l5.00003-5.00003c.1406-.14065.3314-.21967.5303-.21967s.3897.07902.5303.21967l5 5.00003c.2929.2929.2929.7677 0 1.0606z"
      fill-rule="evenodd"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
