<!-- AppIconSortDown.vue -->
<template>
  <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_10503084">
    <path
      class="icon-color"
      clip-rule="evenodd"
      d="m6.46967 8.96967c.29289-.29289.76777-.29289 1.06066 0l4.46967 4.46963 4.4697-4.46963c.2929-.29289.7677-.29289 1.0606 0s.2929.76777 0 1.06063l-5 5c-.1406.1407-.3314.2197-.5303.2197s-.3897-.079-.5303-.2197l-5.00003-5c-.29289-.29286-.29289-.76774 0-1.06063z"
      fill-rule="evenodd"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
