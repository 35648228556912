<!-- SettingsGoogleAdsModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';

import AppModal from '@/components/ui/AppModal.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppToggleButton from '@/components/ui/buttons/AppToggleButton.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue';

import { googleAdCampaigns, accountOptions } from '@/data/settings';
import { propertyGroupOptions } from '@/data/properties';

const modalStore = useModalStore();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedGoogleAdsModal'));
const isLoading = computed(() => modalStore.modalLoading);

// Local states
const selectedAccount = ref('');
const selectedProperty = ref('');
const toggleAutoAddCampaigns = ref('');

// Local loading states for button actions
const unlinkLoading = ref(false);
const resyncLoading = ref(false);
const saveLoading = ref(false);

const handleSave = () => {
  saveLoading.value = true;
  modalStore.simulateSave('syncedGoogleAdsModal');
  setTimeout(() => {
    saveLoading.value = false;
  }, 1000);
};

const handleResync = () => {
  resyncLoading.value = true;
  modalStore.simulateResync();
  setTimeout(() => {
    resyncLoading.value = false;
  }, 1200);
};

const handleUnlink = () => {
  unlinkLoading.value = true;
  modalStore.simulateSave('syncedGoogleAdsModal');
  setTimeout(() => {
    unlinkLoading.value = false;
  }, 1000);
};
</script>

<template>
  <teleport to="body">
    <AppModal title="Google Ads" modal-id="syncedGoogleAdsModal" v-model:isModalOpen="isModalOpen">
      <template #modalBody>
        <!-- Loading State -->
        <div v-if="isLoading" class="loading-state">
          <!-- Account Selector Placeholder -->
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="20px" />
          </div>

          <!-- Property Selector Placeholder -->
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="20px" />
          </div>

          <!-- Campaigns Placeholder -->
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="60px" />
          </div>

          <!-- Toggle Auto-Add Campaigns Placeholder -->
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="60%" height="20px" />
          </div>
        </div>

        <!-- Actual Content -->
        <div v-else>
          <!-- Account Selector -->
          <AppSingleSelector body-width="350px" title="Account" placeholder="Select an account" :options="accountOptions" v-model="selectedAccount" />

          <!-- Property Selector -->
          <AppSingleSelector
            body-width="350px"
            title="Property"
            placeholder="Select a property"
            :options="propertyGroupOptions"
            v-model="selectedProperty"
          />

          <!-- Campaigns -->
          <AppListPills title="Campaigns" placeholder="No campaigns found for this property" :listItems="googleAdCampaigns" />

          <!-- Toggle Auto-Add Campaigns -->
          <div class="toggle-area">
            Automatically add new Ad<br />campaigns to this property
            <AppToggleButton id="autoAddCampaign" label="" v-model="toggleAutoAddCampaigns" />
          </div>
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="unlinkLoading" @click="handleUnlink">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="resyncLoading" @click="handleResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <AppButton label="Save" :isLoading="saveLoading" @click="handleSave" />
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.toggle-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 50px 0;
  font-weight: 700;
}

.modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
  }
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.loading-state {
  padding: 20px;
}

.placeholder-block {
  background: var(--property-card-bg);
  border-radius: 8px;
  padding: 15px;
}
</style>
