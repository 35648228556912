<!-- AppModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import AppIconClose from '@/components/svg/AppIconClose.vue';
import { useModalStore } from '@/stores/ModalStore';

const props = defineProps({
  title: { type: String, required: true },
  modalId: { type: String, required: true },
});

const modalStore = useModalStore();
const isModalOpen = computed(() => modalStore.isModalOpen(props.modalId));

const closeModal = () => {
  modalStore.closeModal(props.modalId);
};

const modalContentRef = ref(null);

onClickOutside(modalContentRef, () => closeModal());
</script>

<template>
  <dialog v-if="isModalOpen" class="modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div :ref="modalContentRef" class="modal-content" @click.stop>
      <!-- Modal header -->
      <div class="modal-header">
        <div class="modal-title-and-icon">
          <h4>{{ props.title }}</h4>
        </div>
        <div @click="closeModal">
          <AppIconClose />
        </div>
      </div>

      <!-- Modal Content -->
      <div class="modal-items">
        <slot name="modalBody"></slot>
      </div>

      <!-- Modal Sub-Footer -->
      <div v-if="$slots.modalSubFooter" class="modal-sub-footer">
        <slot name="modalSubFooter"></slot>
      </div>
      <!-- Modal footer -->
      <div v-if="$slots.modalFooter" class="modal-footer">
        <slot name="modalFooter"></slot>
      </div>
    </div>
  </dialog>
</template>

<style lang="scss" scoped>
dialog {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  max-height: 90vh;
  width: 30rem;
  background-color: transparent;
  z-index: 10002;
  border: none;
}

.modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: visible;
  background-color: var(--element-bg);
  filter: drop-shadow(-1px 49px 50px #000);
  position: relative;
  color: var(--primary-text-color);
  border: 1.677px solid var(--secondary-color);
  border-radius: 13px;

  :deep(.input-label) {
    color: var(--primary-text-color);
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1.677px solid var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 1;

  h4 {
    font-size: 20px;
    font-weight: 700;
  }

  .modal-title-and-icon {
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.modal-items {
  padding: 0px 15px;
  margin: 14px 10px;
  overflow-y: auto;
  flex-grow: 1;

  :deep(.app-multi-selector-container) {
    padding: 10px 0;
  }

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition: width 0.5s ease, background-color 0.5s ease;
    /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.modal-sub-footer {
  padding: 15px 25px;
  position: relative;
  z-index: 1;
  border-top: 1px solid var(--secondary-color);
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  border-top: 1.677px solid var(--secondary-color);
  padding: 15px;
  position: relative;
  bottom: 0;
  z-index: 1;

  .btn {
    display: inline-block;
    border: none;
    background-color: var(--primary-color);
    background: var(--primary-gradient);
    background-size: 100%;
    border-radius: 12px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.019em;
    padding: 13px 20px;
    min-width: 120px;
    min-height: 45px;
    transition: all 0.4s;
  }
}

:deep(.spinner-wrapper) {
  display: flex;
  justify-content: center;
}
</style>
