<script setup>
import { ref } from 'vue';
import { useReportsStore } from '@/stores/ReportsStore';

const reportsStore = useReportsStore();

// temporary placeholder data for the cards
const reports = ref([
  {
    id: 1,
    label: 'Benchmark',
    date: 'May 14 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 2,
    label: 'ETA',
    date: 'Aug 11 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 3,
    label: 'Extension',
    date: 'Nov 14 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 4,
    label: 'Keyword',
    date: 'Jun 9 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 5,
    label: 'RSA',
    date: 'Jul 4 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 6,
    label: 'Trending',
    date: 'Jul 19 2024',
    description:
      'The trending report can be created for a specific community, group of properties, or your entire portfolio. It is a great way to analyze performance on a high level, to see how results have improved or declined over several consistent time periods.',
  },
]);
</script>

<template>
  <div class="report-grid">
    <div v-for="report in reports" :key="report.id" class="report-block">
      <div class="report-content">
        <div class="report-header">
          <div class="report-avatar"></div>
          <div class="report-info">
            <h3>{{ report.label }} Report</h3>
            <p class="last-edited">
              Last edited on <span>{{ report.date }}</span>
            </p>
          </div>
        </div>
        <p class="description">
          {{ report.description }}
        </p>
      </div>
      <div class="buttons">
        <button class="btn view" @click="reportsStore.loadReport(report.id)">View</button>
        <button class="btn btn-outlined">Customize</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/_mixins.scss';

.report-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.report-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--property-card-bg);
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease;
  overflow: hidden;
}

.report-content {
  padding: 25px;
  padding-bottom: 0;
}

.report-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.report-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--property-info-bg);
}

.report-info h3 {
  font-family: Lato;
  font-size: 20.083px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.last-edited {
  margin: 3px 0 0;
  color: var(--secondary-color);
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 300;
  line-height: normal;

  span {
    color: var(--secondary-color);
  }
}

.description {
  font-family: Lato;
  font-size: 14.606px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 15px 0 20px;
}

.buttons {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  background-color: var(--property-info-bg);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* Buttons */
.btn {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  padding: 10px;
  transition: all 0.4s ease;
  white-space: nowrap;
  width: 100%;
}

.view {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-text-color);
}

.btn-outlined {
  background: none;
  border: 1px solid var(--secondary-color);
  color: var(--primary-text-color);

  &:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--primary-text-color);
  }
}

// Medium screens
@media (max-width: 1439px) {
  .report-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Small screens
@media (max-width: 768px) {
  .report-grid {
    grid-template-columns: 1fr;
  }
}
</style>
