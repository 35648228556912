<!-- AppOverlayMenu.vue -->
<script setup>
const props = defineProps({
  x: Number,
  y: Number,
  options: {
    type: Array,
    default: () => [],
  },
  bodyWidth: {
    type: String,
    default: 'auto',
  },
});

const emit = defineEmits(['option-clicked']);

const handleOptionClick = (option) => {
  emit('option-clicked', option);
};
</script>

<template>
  <div class="overlay-container">
    <div class="overlay-menu" :style="{ width: bodyWidth, top: `${y}px`, left: `${x}px` }">
      <!-- Named slot for heading -->
      <slot name="heading"></slot>
      <!-- Options list -->
      <ul>
        <li v-for="option in options" :key="option" @click="handleOptionClick(option)">
          <slot :name="option"></slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlay-container {
  position: relative;
}

.overlay-menu {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 9999;
  background-color: var(--dropdown-bg);
  border-radius: 14px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border: var(--border-size) solid var(--secondary-color);
}

.overlay-menu ul {
  list-style: none;
  margin: 0;
  padding: 5px;
}

.overlay-menu li {
  border-radius: 7px;
  padding: 8px 12px;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.75;
  font-size: 15px;

  &:hover {
    opacity: 1;
    transition: all 0.3s ease;
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}
</style>
