<script setup>
import { computed, reactive, ref } from 'vue';
import AppIconReverseArrows from '@/components/svg/AppIconReverseArrows.vue';
import AppIconKyzen from '@/components/svg/AppIconKyzen.vue';
import AppModal from '@/components/ui/AppModal.vue';
import { useModalStore } from '@/stores/ModalStore';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue'; // NEW: For placeholders

const modalStore = useModalStore();
const data = computed(() => modalStore.modalData);

// Simulate a loading state based on whether integration_name is present.
const isLoading = computed(() => !data.value.integration_name);

const someFunction = () => {
  console.log('Some function');
};

const modalType = computed(() => {
  return data.value.integration_name !== 'Kyzen API' && data.value.integration_name !== 'Chatmeter API';
});

const selectedAccount = ref(null);

const accountOptions = reactive([
  { value: '1', label: 'Account 1' },
  { value: '2', label: 'Account 2' },
  { value: '3', label: 'Account 3' },
]);
</script>

<template>
  <teleport to="body">
    <AppModal :title="data.integration_name" modal-id="settingsNotSyncedModal">
      <template #modalBody>
        <div v-if="isLoading" class="loading-state">
          <!-- Skeleton placeholders to mimic final UI structure -->
          <div class="sync-integration">
            <div class="integration-logos">
              <div class="kyzen-logo">
                <AppSkeletonPlaceholder width="80px" height="80px" borderRadius="50%" />
              </div>
              <AppSkeletonPlaceholder width="30px" height="30px" borderRadius="50%" />
              <div class="integration-logo">
                <AppSkeletonPlaceholder width="80px" height="80px" borderRadius="50%" />
              </div>
            </div>
            <!-- Title Placeholder -->
            <AppSkeletonPlaceholder width="60%" height="20px" style="margin-bottom: 12px" />
            <!-- Paragraph Placeholder -->
            <AppSkeletonPlaceholder width="80%" height="20px" style="margin-bottom: 30px" />
            <!-- Button Placeholder -->
            <AppSkeletonPlaceholder width="150px" height="40px" borderRadius="4px" />
          </div>
        </div>

        <div v-else>
          <!-- Actual content when data is loaded -->
          <div class="sync-integration" v-if="modalType">
            <div class="integration-logos">
              <div class="kyzen-logo">
                <AppIconKyzen />
              </div>
              <AppIconReverseArrows />
              <div class="integration-logo">
                <component :is="data.integration_icon" />
              </div>
            </div>
            <h3>Connect Kyzen to {{ data.integration_name }}</h3>
            <p>Looks like your account isn’t synced yet. Please log in to connect your account.</p>
            <button>Sign into {{ data.integration_name }}</button>
          </div>

          <div class="sync-integration" v-else>
            <div class="integration-logos">
              <div class="kyzen-logo">
                <AppIconKyzen />
              </div>
              <AppIconReverseArrows />
              <div class="integration-logo">
                <component :is="data.integration_icon" />
              </div>
            </div>
            <h3>Connect {{ data.integration_name }} to Kyzen</h3>

            <div class="selector-container">
              <AppSingleSelector
                v-model="selectedAccount"
                :options="accountOptions"
                placeholder="Select an account"
                body-width="150px"
                title="Account"
              />
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
.selector-container {
  padding: 0 120px;
}

.sync-integration {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px 0 20px;
  border-radius: 8px;

  button {
    background-color: #0f9d58;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
  }

  button:hover {
    background-color: #0c7a43;
  }

  h3 {
    color: var(--primary-text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 12px;
  }

  p {
    color: var(--primary-text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-bottom: 30px;
  }

  .integration-logos {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .kyzen-logo {
    height: 80px;
    width: 80px;
    background-color: var(--filter-nav-bg);
    border-radius: 50%;

    svg {
      display: block;
      margin: auto;
      min-width: 100%;
      height: 100%;
      padding: 14px;
    }
  }

  .integration-logo {
    height: 80px;
    width: 80px;
    background-color: var(--filter-nav-bg);
    border-radius: 50%;

    svg {
      display: block;
      margin: auto;
      min-width: 100%;
      height: 100%;
      padding: 15px;
    }
  }
}

.loading-state {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.modal-items) {
  padding: 50px;
}
</style>
