// settings.js

// Google Analytics 4
export const goals = [
  { id: 1, name: 'Dyverse Clicks - Phone Number' },
  { id: 2, name: 'Dyverse Form Submit - Contact Us' },
  { id: 3, name: 'Dyverse Clicks - Apply Now' },
  { id: 4, name: 'Dyverse Clicks - Phone Number' },
  { id: 5, name: 'Dyverse Form Submit - Floor Plans' },
  { id: 6, name: 'Dyverse Form Submit - Schedule Tour' },
  { id: 7, name: 'Dyverse Form Submit - Get Notified' },
];

// Filter Options
export const accountOptions = [
  {
    id: 1,
    name: 'Texas',
    label: 'Texas',
    value: 'texas',
  },
  {
    id: 2,
    name: 'Paramount',
    label: 'Paramount',
    value: 'paramount',
  },
  {
    id: 3,
    name: 'Razz Clients',
    label: 'Razz Clients',
    value: 'razz-clients',
  },
  {
    id: 4,
    name: 'Dweck',
    label: 'Dweck',
    value: 'razz-clients',
  },
  {
    id: 5,
    name: 'Haven',
    label: 'Haven',
    value: 'razz-clients',
  },
  {
    id: 6,
    name: 'Lex and Leo at Waterfront Station',
    label: 'Lex and Leo at Waterfront Station',
    value: 'lex-and-leo-at-waterfront-station',
  },
];

export const properties = [
  {
    id: 1,
    name: 'URBN City Lofts',
    value: 'urbn-city-lofts',
  },
  {
    id: 2,
    name: 'URBN Flats',
    value: 'urbn-flats',
  },
  {
    id: 3,
    name: 'URBN Living',
    value: 'urbn-living',
  },
  {
    id: 4,
    name: 'URBN Miami',
    value: 'urbn-miami',
  },
  {
    id: 5,
    name: 'URBN Apartments',
    value: 'urbn-apartments',
  },
  {
    id: 6,
    name: 'Valor at the Realm',
    value: 'valor-at-the-realm',
  },
  {
    id: 7,
    name: 'Viceroy Townhomes',
    value: 'viceroy-townhomes',
  },
  {
    id: 8,
    name: 'Verano',
    value: 'verano',
  },
];

export const propertyID = [
  {
    id: 1,
    propertyID: '420598989',
    value: '420598989',
  },
];

export const webStreamID = [
  {
    id: 1,
    propertyID: 'G-XKN1QXGX49',
    value: 'G-XKN1QXGX49',
  },
];

export const pagePath = [
  {
    id: 1,
    name: '/',
    value: '/',
  },
  {
    id: 2,
    name: '/floor-plans',
    value: '/floor-plans',
  },
  {
    id: 3,
    name: '/amenities',
    value: '/amenities',
  },
  {
    id: 4,
    name: '/availableunits',
    value: '/availableunits',
  },
  {
    id: 5,
    name: '/brochure',
    value: '/brochure',
  },
  {
    id: 6,
    name: '/contactus',
    value: '/contactus',
  },
];

// Google Ads
export const googleAdCampaigns = [
  { id: 1, name: 'Nationwide' },
  { id: 2, name: 'Local' },
  { id: 3, name: 'In-Market Audiences' },
  { id: 4, name: 'Local' },
  { id: 5, name: 'Site Remarketing' },
];

// GBP
export const googleBusinessAccountOptions = [
  {
    id: 1,
    name: 'John Todero',
    label: 'John Todero',
    value: 'john-todero',
  },
  {
    id: 2,
    name: 'Razz Interactive',
    label: 'Razz Interactive',
    value: 'razz-interactive',
  },
];

// Permissions Data
export const kyzenSubscription = [
  {
    id: 1,
    label: '20715139508 - URBN City Lofts (Kyzen Subscription Pipeline)',
    name: '20715139508 - URBN City Lofts (Kyzen Subscription Pipeline)',
    value: '20715139508 - URBN City Lofts (Kyzen Subscription Pipeline)',
  },
  {
    id: 2,
    label: '20715139508 - URBN Apartments (Kyzen Subscription Pipeline)',
    name: '20715139508 - URBN Apartments (Kyzen Subscription Pipeline)',
    value: '20715139508 - URBN Apartments (Kyzen Subscription Pipeline)',
  },
  {
    id: 3,
    label: '78478746359 - URBN Flats (Kyzen Subscription Pipeline)',
    name: '78478746359 - URBN Flats (Kyzen Subscription Pipeline)',
    value: '78478746359 - URBN Flats (Kyzen Subscription Pipeline)',
  },
  {
    id: 4,
    label: '12273794849 - URBN Miami (Kyzen Subscription Pipeline)',
    name: '12273794849 - URBN Miami (Kyzen Subscription Pipeline)',
    value: '12273794849 - URBN Miami (Kyzen Subscription Pipeline)',
  },
  {
    id: 5,
    label: '83030839389 - URBN Living (Kyzen Subscription Pipeline)',
    name: '83030839389 - URBN Living (Kyzen Subscription Pipeline)',
    value: '83030839389 - URBN Living (Kyzen Subscription Pipeline)',
  },
];

export const reportsPermissions = [
  {
    id: 1,
    report_type: 'automated_report',
    name: 'Automated Reports',
  },
  {
    id: 2,
    report_type: 'amazon_report',
    name: 'Amazon Report',
  },
  {
    id: 3,
    report_type: 'wall_report',
    name: 'WALL Report',
  },
  {
    id: 4,
    report_type: 'portfolio_report',
    name: 'Bernstein Portfolio Report',
  },
];

export const permissionTools = [
  {
    id: 5,
    tool: 'price_extensions',
    name: 'Price Extensions',
  },
  {
    id: 6,
    tool: 'budget_tool',
    name: 'Budget Tool',
  },
];

// Profile Data

export const portfolioGroups = [
  { id: 1, name: 'Dyverse' },
  { id: 2, name: 'MACK Management' },
];

export const customGroups = [
  { id: 1, name: 'URBN' },
  { id: 2, name: 'Miami' },
  { id: 3, name: 'Nonsi Nxumalo' },
  { id: 4, name: 'Sebastian Tovar-Morales' },
];

export const smartGroups = [
  { id: 1, name: 'Exposure Risk' },
  { id: 2, name: 'Google Ads' },
  { id: 3, name: 'Ads Manager verified' },
];

export const hubspotGroups = [
  { id: 1, name: 'Dyverse' },
  { id: 2, name: 'URBN' },
  { id: 3, name: 'Miami' },
  { id: 4, name: 'Florida' },
  { id: 5, name: 'Nonsi Nxumalo' },
  { id: 6, name: 'Sebastian Tovar-Morales' },
];
