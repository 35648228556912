<!-- SettingsGTMModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import AppModal from '@/components/ui/AppModal.vue';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import AppCheckboxButton from '@/components/ui/buttons/AppCheckboxButton.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import AppIconDownload from '@/components/svg/AppIconDownload.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue'; // NEW: for placeholders
import useSearch from '@/composables/useSearch';

import { goals, accountOptions, properties } from '@/data/settings';

const modalStore = useModalStore();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedGoogleTagManagerModal'));
const isLoading = computed(() => modalStore.modalLoading);

// Local state for search and selected options
const { searchValue: accountSearchValue, filteredOptions: filteredAccounts } = useSearch(accountOptions);
const { searchValue: propertySearchValue, filteredOptions: filteredProperties } = useSearch(properties);

const selectedAccounts = ref([]);
const selectedProperties = ref([]);

// Local loading states for button actions
const unlinkLoading = ref(false);
const resyncLoading = ref(false);
const saveLoading = ref(false);

function handleAccountSelection(option) {
  const index = selectedAccounts.value.findIndex((acc) => acc.id === option.id);
  if (index > -1) {
    selectedAccounts.value.splice(index, 1);
  } else {
    selectedAccounts.value.push(option);
  }
}

function handlePropertySelection(option) {
  const index = selectedProperties.value.findIndex((prop) => prop.id === option.id);
  if (index > -1) {
    selectedProperties.value.splice(index, 1);
  } else {
    selectedProperties.value.push(option);
  }
}

function isAccountChecked(accountId) {
  return selectedAccounts.value.some((acc) => acc.id === accountId);
}

function isPropertyChecked(propertyId) {
  return selectedProperties.value.some((prop) => prop.id === propertyId);
}

function handleSave() {
  saveLoading.value = true;
  modalStore.simulateSave('syncedGoogleTagManagerModal');
  setTimeout(() => {
    saveLoading.value = false;
  }, 1000);
}

function handleResync() {
  resyncLoading.value = true;
  modalStore.simulateResync();
  setTimeout(() => {
    resyncLoading.value = false;
  }, 1200);
}

function handleUnlink() {
  unlinkLoading.value = true;
  modalStore.simulateSave('syncedGoogleTagManagerModal');
  setTimeout(() => {
    unlinkLoading.value = false;
  }, 1000);
}
</script>

<template>
  <teleport to="body">
    <AppModal title="Google Tag Manager" modal-id="syncedGoogleTagManagerModal" v-model:isModalOpen="isModalOpen">
      <template #modalBody>
        <!-- Loading State -->
        <div v-if="isLoading" class="loading-state">
          <!-- Account Selector Placeholder -->
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="20px" />
          </div>

          <!-- Properties Selector Placeholder -->
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="20px" />
          </div>

          <!-- Goals Placeholder -->
          <div class="placeholder-block" style="margin-top: 20px; height: 300px">
            <AppSkeletonPlaceholder width="150px" height="20px" />
            <div style="margin-top: 10px; display: flex; gap: 10px">
              <AppSkeletonPlaceholder width="90px" height="20px" />
              <AppSkeletonPlaceholder width="90px" height="20px" />
              <AppSkeletonPlaceholder width="90px" height="20px" />
              <AppSkeletonPlaceholder width="90px" height="20px" />
            </div>
          </div>
        </div>

        <!-- Actual Content -->
        <div v-else>
          <!-- Account MultiSelector -->
          <AppMultiSelector title="Account" v-model:selectedOptions="selectedAccounts" placeholder="Select one or more accounts">
            <template #body>
              <AppSearch placeholder="Search accounts..." v-model="accountSearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="account in filteredAccounts" :key="account.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="account.name"
                      :is-check="true"
                      :is-checked="isAccountChecked(account.id)"
                      @checkboxClicked="handleAccountSelection(account)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Properties MultiSelector -->
          <AppMultiSelector title="Properties" v-model:selectedOptions="selectedProperties" placeholder="Select one or more properties">
            <template #body>
              <AppSearch placeholder="Search properties..." v-model="propertySearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="property in filteredProperties" :key="property.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="property.name"
                      :is-check="true"
                      :is-checked="isPropertyChecked(property.id)"
                      @checkboxClicked="handlePropertySelection(property)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Goals ListBox -->
          <AppListPills title="Goals" :listItems="goals" />
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="unlinkLoading" @click="handleUnlink">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="resyncLoading" @click="handleResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <div class="script-buttons">
            <span>
              <AppIconDownload />
              GTM
            </span>
            <span>
              <AppIconCopy />
              GTM
            </span>
          </div>
          <div>
            <AppButton label="Save" :isLoading="saveLoading" @click="handleSave" />
          </div>
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.copy-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.modal-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.script-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  line-height: 39.378px;
  letter-spacing: -0.342px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  :deep(.icon-color) {
    fill: var(--primary-text-color);

    &:hover {
      fill: var(--primary-text-color);
    }
  }
}

.loading-state {
  padding: 20px;
}

.placeholder-block {
  background: var(--property-card-bg);
  border-radius: 8px;
  padding: 15px;
}
</style>
