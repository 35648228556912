import { markRaw, defineAsyncComponent } from 'vue';

export const icons = {
  AppGoogleAdsIcon: markRaw(defineAsyncComponent(() => import('@/components/svg/AppGoogleAdsIcon.vue'))),
  AppGa4Icon: markRaw(defineAsyncComponent(() => import('@/components/svg/AppGa4Icon.vue'))),
  AppMetaAdsIcon: markRaw(defineAsyncComponent(() => import('@/components/svg/AppMetaAdsIcon.vue'))),
  AppGBPIcon: markRaw(defineAsyncComponent(() => import('@/components/svg/AppGBPIcon.vue'))),
  AppGTMIcon: markRaw(defineAsyncComponent(() => import('@/components/svg/AppGTMIcon.vue'))),
  // Add other icons as needed
};

export const previews = {
  AppWidgetPreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppWidgetPreview.vue'))),
  AppDonutPreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppDonutPreview.vue'))),
  AppTablePreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppTablePreview.vue'))),
  AppLinePreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppLinePreview.vue'))),
  AppBarPreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppBarPreview.vue'))),
  AppOtherPreview: markRaw(defineAsyncComponent(() => import('@/components/svg/AppOtherPreview.vue'))),
  // Add other previews as needed
};
