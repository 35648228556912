// src/composables/useTableStyles.js
import { computed } from 'vue';

export default function useTableStyles(data, multiplier, options = {}) {
  const getLongestString = (data) =>
    Math.max(
      ...data.map((item) =>
        Math.max(
          ...Object.values(item)
            .filter((value) => typeof value === 'string')
            .map((value) => value.length)
        )
      )
    );

  const longestString = getLongestString(data);
  const minColumnWidth = computed(() => longestString * multiplier);
  const cellStyle = computed(() => ({ '--cell-base-size': `${minColumnWidth.value}px` }));

  // Active column logic
  let activeDataKey = computed(() => null);
  if (options.tableId && options.tableStore) {
    const tableId = options.tableId;
    const tableStore = options.tableStore;
    activeDataKey = computed(() => {
      const sortingInfo = tableStore.sorting[tableId];
      return sortingInfo ? sortingInfo.dataKey : null;
    });
  }

  const getCellClass = (columnKey) => {
    return {
      'active-column': activeDataKey.value === columnKey,
    };
  };

  return { cellStyle, minColumnWidth, getCellClass };
}
