<!-- SettingsChatMeterModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import AppModal from '@/components/ui/AppModal.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue';
import { propertyGroupOptions } from '@/data/properties';

const modalStore = useModalStore();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedChatmeterAPIModal'));
const isLoading = computed(() => modalStore.modalLoading);

// Local state
const selectedProperty = ref('');

// Local loading states for button actions
const unlinkLoading = ref(false);
const resyncLoading = ref(false);
const saveLoading = ref(false);

const handleSave = () => {
  saveLoading.value = true;
  modalStore.simulateSave('syncedChatmeterAPIModal');
  setTimeout(() => {
    saveLoading.value = false;
  }, 1000);
};

const handleResync = () => {
  resyncLoading.value = true;
  modalStore.simulateResync();
  setTimeout(() => {
    resyncLoading.value = false;
  }, 1200);
};

const handleUnlink = () => {
  unlinkLoading.value = true;
  modalStore.simulateSave('syncedChatmeterAPIModal');
  setTimeout(() => {
    unlinkLoading.value = false;
  }, 1000);
};
</script>

<template>
  <teleport to="body">
    <AppModal title="Chatmeter API" modal-id="syncedChatmeterAPIModal" v-model:isModalOpen="isModalOpen" class="single-component-modal">
      <template #modalBody>
        <!-- Loading State -->
        <div v-if="isLoading" class="loading-state">
          <!-- Property Selector Placeholder -->
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="20px" />
          </div>
        </div>

        <div v-else>
          <!-- Property Selector -->
          <AppSingleSelector
            body-width="350px"
            title="Property"
            placeholder="Select a property"
            :options="propertyGroupOptions"
            v-model="selectedProperty"
          />
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="unlinkLoading" @click="handleUnlink">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="resyncLoading" @click="handleResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <AppButton label="Save" :isLoading="saveLoading" @click="handleSave" />
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

// By targeting .modal-items with :deep, we are only overriding the overflow-y property specifically for this modal instance.
// I'm using this approach to ensure that the change only affects SettingsChatMeterModal and does not impact other modals using the same AppModal component. We can convert this to a conditional prop based class if preferred by the team. This will still maintain proper CSS specificity and cascade rules over using !important. -Ev
:deep(.modal-items) {
  overflow-y: visible;
}

.toggle-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 50px 0;
  font-weight: 700;
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.modal-controls {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-grow: 1;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.loading-state {
  padding: 20px;
}

.placeholder-block {
  background: var(--property-card-bg);
  border-radius: 8px;
  padding: 15px;
}
</style>
