<script setup>
import { defineProps, computed } from 'vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';
import { useSettingsStore } from '@/stores/SettingsStore';

const settingsStore = useSettingsStore();
const integrations = computed(() => settingsStore.allIntegrations);

const filteredIntegrations = computed(() => {
  return integrations.value.filter((integration) => integration.integration_sync_status === true);
});

const props = defineProps({
  title: { type: String, required: true },
  lastEdited: { type: String, required: true },
  buttonText: { type: String, required: true },
  buttonType: { type: String, default: 'view' },
  dashboardId: { type: Number, required: true }, // Pass dashboardId as a prop
});

const emit = defineEmits(['button-click']); // Define custom event

const buttonClass = computed(() => {
  return props.buttonType === 'view' ? 'view-button' : 'add-button';
});

const handleClick = () => {
  emit('button-click', props.dashboardId);
};
</script>

<template>
  <div class="dashboard-card">
    <div class="dashboard-header">
      <div class="dashboard-info">
        <span class="dashboard-title">{{ title }}</span>
        <span class="dashboard-last-edited">{{ lastEdited }}</span>
      </div>
      <button :class="buttonClass" @click="handleClick">{{ buttonText }}</button>
    </div>
    <div class="dashboard-footer">
      <div v-if="filteredIntegrations.length > 0" class="footer-content">
        <div v-for="integration in filteredIntegrations" :key="integration.id">
          <AppTooltip>
            <component :is="integration.integration_icon" />
            <template #text>
              <span class="description">{{ integration.sync_message }}</span>
            </template>
          </AppTooltip>
        </div>
      </div>
      <div v-else>
        <p class="no-connections">This dashboard does not contain any integrations. <a href="/settings">Setup connections</a></p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dashboard-card {
  background-color: var(--property-card-bg);
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 10px 0px 15px;
}

.dashboard-info {
  display: flex;
  flex-direction: column;

  .dashboard-last-edited {
    color: var(--muted-text);
    font-family: Lato;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    margin-top: 3px;
  }
}

.dashboard-title {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.view-button,
.add-button {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 10.033px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: #ffffff;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-button:hover,
.add-button:hover {
  background-color: #218838;
}

.dashboard-footer {
  display: flex;
  gap: 10px;
  padding: 16px;
  color: var(--secondary-color);
  background-color: var(--property-info-bg);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .footer-content {
    display: flex;
    gap: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .no-connections {
    font-size: 12px;
    opacity: 0.5;
  }
}
</style>
