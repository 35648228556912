<!-- AppListPills.vue -->
<script setup>
import { ref, computed, watch } from 'vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import { onClickOutside } from '@vueuse/core';

// Props
const props = defineProps({
  title: { type: String, required: false },
  listItems: { type: Array, required: true }, // List of items to display
  modelValue: { type: Array, default: () => [] }, // Default selected items
  placeholder: { type: String, default: 'Click to add' },
  disabled: { type: Boolean, default: false },
});

// Emit for the v-model
const emit = defineEmits(['update:modelValue']);

// State for the selected items, search query, and dropdown visibility
const selectedItems = ref([...props.modelValue]); // Initializing with modelValue
const searchQuery = ref('');
const dropdownOpen = ref(false);
const dropdownPosition = ref({ top: 0, left: 0 });

// Watch for changes in modelValue to update the selectedItems
watch(
  () => props.modelValue,
  (newValue) => {
    selectedItems.value = [...newValue];
  }
);

// Watch for changes in selectedItems and update the modelValue
watch(
  () => props.modelValue,
  (newValue) => {
    selectedItems.value = [...newValue];
  }
);

// Computed list for filtering items based on search query
const filteredItems = computed(() => {
  return props.listItems.filter((item) => item.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

// Toggle item selection
const toggleSelection = (item) => {
  if (props.disabled) return;
  const index = selectedItems.value.findIndex((selected) => selected.id === item.id);
  if (index > -1) {
    // remove selected item if selected
    selectedItems.value.splice(index, 1);
  } else {
    // if it is not selected, add it
    selectedItems.value.push(item);
  }
  emit('update:modelValue', selectedItems.value);
};

// Remove item from selected list using the pills closed button
const removeItem = (itemId) => {
  selectedItems.value = selectedItems.value.filter((item) => item.id !== itemId);
  emit('update:modelValue', selectedItems.value);
};

// Toggle dropdown on click
const toggleDropdown = (event) => {
  if (props.disabled) return;

  dropdownOpen.value = !dropdownOpen.value;

  if (dropdownOpen.value) {
    // getting the bounding box of the parent container
    const parentRect = target.value.getBoundingClientRect();

    dropdownPosition.value = {
      top: event.clientY + window.scrollY - parentRect.top + 10,
      left: Math.min(event.clientX + window.scrollX - parentRect.left, parentRect.width - 200),
    };
  }
};

// Watch searchQuery to open dropdown when typing in search
watch(searchQuery, (newValue) => {
  if (newValue.length > 0) {
    dropdownOpen.value = true;
  }
});

// Close dropdown if clicked outside the dropdown area
const target = ref(null);
onClickOutside(target, () => {
  if (dropdownOpen.value) {
    dropdownOpen.value = false;
  }
});
</script>

<template>
  <div class="listbox-pill-dropdown-container" ref="target">
    <label v-if="title" class="input-label">{{ title }}</label>

    <div class="selected-items" @click="toggleDropdown($event)" :class="{ disabled: disabled }">
      <div v-for="item in selectedItems" :key="item.id" class="pill">
        {{ item.name }}
        <button @click.stop="removeItem(item.id)" class="remove-button">x</button>
      </div>
      <div v-if="selectedItems.length === 0" class="placeholder">{{ placeholder }}</div>
    </div>

    <div v-if="dropdownOpen" class="dropdown-wrapper" :style="{ top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }">
      <div class="dropdown-body">
        <AppSearch v-model="searchQuery" />
        <div v-if="filteredItems.length" class="dropdown-list">
          <ul>
            <li v-for="item in filteredItems" :key="item.id" class="dropdown-item" @click="toggleSelection(item)">
              <label class="checkbox-container">
                <input type="checkbox" :checked="selectedItems.some((selected) => selected.id === item.id)" @change="toggleSelection(item)" />
                <span class="custom-checkbox"></span>
                {{ item.name }}
              </label>
            </li>
          </ul>
        </div>
        <div v-else class="no-results">No results found.</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}

.listbox-pill-dropdown-container {
  padding: 10px 0;
  position: relative;

  .selected-items.disabled {
    cursor: not-allowed;
    background: rgba(232, 231, 231, 0.08);

    + .dropdown-wrapper {
      display: none;
    }

    .pill {
      &:hover {
        .remove-button {
          visibility: hidden;
        }
      }
    }
  }
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  align-content: flex-start;
  height: auto;
  min-height: 250px;
  margin-bottom: 10px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 12px;
  background: none;
  padding: 12px;
  color: var(--primary-text-color);
  transition: 0.4s;
  cursor: pointer;
  overflow-y: auto;

  .pill {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    color: #fff;
    padding: 5px 8px 5px 20px;
    border-radius: 15px;
    font-size: 14px;
    height: 30px;
    transition: all 0.4s ease;

    &:hover {
      .remove-button {
        visibility: visible;
      }
    }

    .remove-button {
      background: none;
      border: none;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-left: 8px;
      position: relative;
      bottom: 1px;
      visibility: hidden;
    }
  }

  .placeholder {
    color: var(--secondary-color);
    font-size: 14px;
  }
}

.dropdown-wrapper {
  position: absolute;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  margin-top: 5px;
  padding: 15px;
  height: auto;
  min-width: 300px;
  overflow: auto;
  z-index: 10;

  .dropdown-body {
    position: relative;
  }

  .dropdown-list {
    list-style-type: none;
    margin: 15px 0 0;
    max-height: 300px;
    overflow-y: auto;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 4px;
      font-size: 16px;
      border-radius: 5px;

      &:hover {
        background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
      }
    }

    &::-webkit-scrollbar {
      width: 9px;
      transition: width 0.5s ease, background-color 0.5s ease;
      scrollbar-gutter: auto;
    }

    &::-webkit-scrollbar-track {
      background: var(--property-card-bg);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(var(--secondary-color-rgb), 0.4);
      transition: background 0.3s ease;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(var(--secondary-color-rgb), 1);
    }
  }

  .no-results {
    margin-top: 10px;
    color: var(--secondary-color);
    font-size: 14px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkbox {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: none;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    margin-right: 10px;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
  }

  input[type='checkbox']:checked + .custom-checkbox {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
  }

  input[type='checkbox']:checked + .custom-checkbox::after {
    display: block;
  }

  .custom-checkbox::after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>
